let url = 'https://pl.imagility.co';

export const environment = {
  production: true,
  version: '1.0.1',
  baseUrl: url+':5651/api/v1',
  docs: url+':5651/docs/',
  appUrl: url,
  affiniPayUrl: 'https://secure.affinipay.com/oauth/authorize',
  affiniPayClientId: '242a356c9a995e55c1391420f9f33975030584f90fd69a49950d4dc51f2231f9',
  secretKey: "8b02d62fa5cbca15116cdedcf8307b1b0efaeca7258911be36cb750987317b4d",
  // This needs to be updated with PROD details
  zoomClientId: 'KKW9GWHQRr2OHdd7vQc8Ww',
  zoomRedirectUrl: 'https://pl.imagility.co/compliance/callback',
  imagilityZoomLink: 'https://zoom.us/j/5437953834?pwd=chyAQy2fb3kmwcWJMXJyDj81wjMRAn.1',
  gmailCompany: [239, 240]
};
